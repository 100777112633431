<template>
    <b-button @click="onClick"
              class="d-flex align-items-center justify-content-center font-weight-bold cursor-pointer rounded-0 text-center my-primary w-100"
              :disabled="isSubmitted">
        <h1 class="text-white d-inline mr-3">{{ saveText }}</h1>
        <b-spinner v-if="isSubmitted"></b-spinner>
    </b-button>
</template>

<script>
export default {
    name: "ButtonPayComponent",
    data() {
        return {
            isSubmitted: false
        }
    },
    props: {
        saveText: {
            default: 'Оплатить',
            type: String
        }
    },
    methods: {
        onClick() {
            this.$emit('onClickButton');
        }
    },
    mounted() {
        window.addEventListener('sale-is-submitted', (event) => {
            this.isSubmitted = event.detail.isSubmitted;
        });
    }
}
</script>

<style scoped>

</style>