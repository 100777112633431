<template>
    <div class="d-xl-flex">
        <div class="mx-3">
            <p>Включить клавиатуру</p>
            <Switches id="keyboard-tool-mode-switch" v-model="includeKeyboard" theme="custom" class="vue-switcher-small"
                      color="primary" :emit-on-mount="false"/>
        </div>
        <div class="mx-3">
            <p>{{ translate.translate("frequentlyRepeated", "PreduprejdeniyaPriProdajeVDolg") }}</p>
            <Switches id="debt-notification-tool-mode-switch" v-model="debtNotification" theme="custom"
                      class="vue-switcher-small" color="primary" :emit-on-mount="false"/>
        </div>
        <div class="mx-3">
            <p>Sotuvda hodimni biriktrish</p>
            <Switches id="checked-sale-employee-localstorage-changed" v-model="isCheckedSaleEmployee" theme="custom"
                      class="vue-switcher-small" color="primary" :emit-on-mount="false"/>
        </div>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import translate from "../../translation/translate";

export default {
    name: "SaleSettings",
    components: {
        Switches
    },
    data() {
        return {
            translate: translate,
            includeKeyboard: true,
            debtNotification: false,
            isCheckedSaleEmployee: false,
        }
    },
    watch: {
        isCheckedSaleEmployee(val) {
            localStorage.setItem('isCheckedSaleEmployee', val)

            window.dispatchEvent(new CustomEvent('checked-sale-employee-localstorage-changed', {
                detail: {
                    storage: localStorage.getItem('isCheckedSaleEmployee')
                }
            }));
        },
        includeKeyboard(val) {
            localStorage.setItem('includeKeyboard', val)

            window.dispatchEvent(new CustomEvent('include-keyboard-key-localstorage-changed', {
                detail: {
                    storage: localStorage.getItem('includeKeyboard')
                }
            }));
        },
        debtNotification(val) {
            localStorage.setItem('debtNotification', val)

            window.dispatchEvent(new CustomEvent('debt-notification-localstorage-changed', {
                detail: {
                    storage: localStorage.getItem('debtNotification')
                }
            }));
        }
    },
    mounted() {
        this.includeKeyboard = localStorage.getItem('includeKeyboard') !== 'false';
        this.debtNotification = localStorage.getItem('debtNotification') === 'true';
        this.isCheckedSaleEmployee = localStorage.getItem('isCheckedSaleEmployee') === 'true';
    }
}
</script>

<style scoped>

</style>