import BaseService from "../base/base.service";
import axios from "axios";

class ClientPaymentService extends BaseService {
    get modelName() {
        return "ClientPayment";
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }

    getAll({query, skip, limit, sort, desc, clientId, startDate, endDate}) {
        let params = {
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            desc: !!desc,
            skip: skip,
            limit: limit,
            client: clientId ? clientId : null,
            start: this._getDateDDMMYYYFormat(startDate),
            end: this._getDateDDMMYYYFormat(endDate)
        };
        return axios.get(this.getAllUrl(), {params: params})
            .then(response => {
                return response.data;
            }).catch((error) => {
                return Promise.reject(error)
            });
    }

    create({
               Description,
               ClientId,
               CreatedAt,
               PaymentCashCurrencyValue,
               PaymentCardCurrencyValue,
               PaymentBankTransferCurrencyValue,
               HumoTransferCurrencyValue,
               OtherTransferCurrencyValue
           }) {
        let paymentCashCurrencyValue = PaymentCashCurrencyValue.Value === 0 ? null : {
            Value: PaymentCashCurrencyValue.Value,
            Rate: PaymentCashCurrencyValue.Rate,
            CurrencyId: PaymentCashCurrencyValue.CurrencyId
        }
        let paymentCardCurrencyValue = PaymentCardCurrencyValue.Value === 0 ? null : {
            Value: PaymentCardCurrencyValue.Value,
            Rate: PaymentCardCurrencyValue.Rate,
            CurrencyId: PaymentCardCurrencyValue.CurrencyId
        }
        let paymentBankTransferCurrencyValue = PaymentBankTransferCurrencyValue.Value === 0 ? null : {
            Value: PaymentBankTransferCurrencyValue.Value,
            Rate: PaymentBankTransferCurrencyValue.Rate,
            CurrencyId: PaymentBankTransferCurrencyValue.CurrencyId
        }
        let humoTransferCurrencyValue = HumoTransferCurrencyValue.Value === 0 ? null : {
            Value: HumoTransferCurrencyValue.Value,
            Rate: HumoTransferCurrencyValue.Rate,
            CurrencyId: HumoTransferCurrencyValue.CurrencyId
        }
        let otherTransferCurrencyValue = OtherTransferCurrencyValue.Value === 0 ? null : {
            Value: OtherTransferCurrencyValue.Value,
            Rate: OtherTransferCurrencyValue.Rate,
            CurrencyId: OtherTransferCurrencyValue.CurrencyId
        }

        return axios.post(this.getCreateUrl(), {
            PaymentCashCurrencyValue: paymentCashCurrencyValue,
            PaymentCardCurrencyValue: paymentCardCurrencyValue,
            PaymentBankTransferCurrencyValue: paymentBankTransferCurrencyValue,
            HumoTransferCurrencyValue: humoTransferCurrencyValue,
            OtherTransferCurrencyValue: otherTransferCurrencyValue,
            Description: Description || null,
            ClientId: ClientId,
            CreatedAt: CreatedAt ? this._getDateDDMMYYYFormat(CreatedAt) : null,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }

    edit(ClientPayment) {
        let paymentCashCurrencyValue = ClientPayment.PaymentCashCurrencyValue.Value === 0 ? null : {
            Value: ClientPayment.PaymentCashCurrencyValue.Value,
            Rate: ClientPayment.PaymentCashCurrencyValue.Rate,
            CurrencyId: ClientPayment.PaymentCashCurrencyValue.CurrencyId
        }
        let paymentCardCurrencyValue = ClientPayment.PaymentCardCurrencyValue.Value === 0 ? null : {
            Value: ClientPayment.PaymentCardCurrencyValue.Value,
            Rate: ClientPayment.PaymentCardCurrencyValue.Rate,
            CurrencyId: ClientPayment.PaymentCardCurrencyValue.CurrencyId
        }
        let paymentBankTransferCurrencyValue = ClientPayment.PaymentBankTransferCurrencyValue.Value === 0 ? null : {
            Value: ClientPayment.PaymentBankTransferCurrencyValue.Value,
            Rate: ClientPayment.PaymentBankTransferCurrencyValue.Rate,
            CurrencyId: ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId
        }
        let humoTransferCurrencyValue = ClientPayment.HumoTransferCurrencyValue.Value === 0 ? null : {
            Value: ClientPayment.HumoTransferCurrencyValue.Value,
            Rate: ClientPayment.HumoTransferCurrencyValue.Rate,
            CurrencyId: ClientPayment.HumoTransferCurrencyValue.CurrencyId
        }
        let otherTransferCurrencyValue = ClientPayment.OtherTransferCurrencyValue.Value === 0 ? null : {
            Value: ClientPayment.OtherTransferCurrencyValue.Value,
            Rate: ClientPayment.OtherTransferCurrencyValue.Rate,
            CurrencyId: ClientPayment.OtherTransferCurrencyValue.CurrencyId
        }
        return axios.put(this.getEditUrl(ClientPayment.ClientPaymentId), {
            PaymentCashCurrencyValue: paymentCashCurrencyValue,
            PaymentCardCurrencyValue: paymentCardCurrencyValue,
            PaymentBankTransferCurrencyValue: paymentBankTransferCurrencyValue,
            HumoTransferCurrencyValue: humoTransferCurrencyValue,
            OtherTransferCurrencyValue: otherTransferCurrencyValue,

            Description: ClientPayment.Description || null,
            ClientId: ClientPayment.ClientId,
            CreatedAt: ClientPayment.CreatedAt ? this._getDateDDMMYYYFormat(new Date(ClientPayment.CreatedAt)) : null,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then(() => {
        }).catch((error) => {
            return Promise.reject(error)
        });
    }
}

export default new ClientPaymentService()
