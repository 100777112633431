<template>
    <b-modal id="ItemSearchBarModal" hide-header hide-footer>
        <GlobalEvents
            @keyup="keyUpHandlingInItemSearchBar"
            @keyup.enter="onKeyupEnter"
            @keyup.esc="$emit('cancel')"
        />
        <div class="d-flex justify-content-between">
            <b-input
                :value="search"
                class="input d-inline border-0 w-90"
                style="font-size: 22px;"
                @input="onInputChange"
                :placeholder='translation.translate("frequentlyRepeated", "vyberiteTovar")'
                ref="SearchInput"
                autofocus
            />
            <div class="d-flex float-right mt-3 ml-4 text-muted">
                <span class="h5 cursor-pointer" @click="$bvModal.hide('ItemSearchBarModal')">X</span>
            </div>
        </div>
        <b-table
            ref="datatable"
            :items="Items"
            :fields="fields"
            :no-footer-sorting="true"
            select-mode="single"
            show-empty
            :responsive="true"
            selectable
            @row-dblclicked="onRowDblClicked"
            :tbody-tr-class="selectedRowClass"
            :sticky-header="stickyHeader"
        >
            <template v-slot:cell(#)="scope">
                {{ scope.index + 1 }}
            </template>
            <template v-slot:cell(name)="scope">
                {{ scope.item.Item.Name }}
            </template>
            <template v-slot:cell(barcode)="scope">{{ scope.item.Item.Barcode }}</template>
            <template v-slot:cell(price)="scope">{{
                    scope.item.Item.PriceCurrencyValue && scope.item.Item.PriceCurrencyValue.Value | division | decimal
                }}
                {{
                    scope.item.Item.PriceCurrencyValue && scope.item.Item.PriceCurrencyValue.Currency ? scope.item.Item.PriceCurrencyValue.Currency.Name : ''
                }}
            </template>
            <template v-slot:cell(state)="scope">
                {{ scope.item.State | decimal }}
                {{ scope.item.Item.Measurement }}
            </template>
            <template v-slot:cell(package)="scope">
                {{ scope.item.PackState | decimal }}
                {{ scope.item.Item.PackageName }}
            </template>
            <template v-slot:cell(empty)>
                <h4 class="text-center">{{ translation.translate("frequentlyRepeated", "netDanniyx") }}</h4>
            </template>
        </b-table>
    </b-modal>
</template>

<script>
import WarehouseItemService from "@/services/warehouse/warehouse.item.service";
import translation from "@/translation/translate";
import GlobalEvents from "vue-global-events";
import {debounce} from "underscore";

export default {
    name: "ItemSearchBar",
    components: {GlobalEvents},
    data: () => ({
        search: "",
        Items: [],
        translation: translation,
        selectedRow: null,
        allowKeyboard: false,
        isAllowedKeyboard: localStorage.getItem("retailSaleKeyboard") !== "banned"
    }),
    computed: {
        stickyHeader() {
            return (window.innerHeight / 1.2).toString() + "px";
        },
        fields() {
            return [
                '#',
                {key: "name", label: translation.translate("frequentlyRepeated", "tovar")},
                {key: "barcode", label: translation.translate("frequentlyRepeated", "kod")},
                {key: "price", label: translation.translate("frequentlyRepeated", "sena")},
                {
                    key: "state",
                    label: translation.translate("frequentlyRepeated", "ostatok")
                },
                {
                    key: "package",
                    label: translation.translate("frequentlyRepeated", "ostatokYedIzm")
                },
            ]
        },
    },
    methods: {
        toggleSimpleKeyboard() {
            let vm = this;
            vm.allowKeyboard = !vm.allowKeyboard;
        },
        onKeyupEnter() {
            if (this.selectedRow)
                this.$emit('selectedItem', this.selectedRow);
        },
        selectedRowClass(item) {
            if (!item || !this.selectedRow) {
                return '';
            }
            if (item.WarehouseItemId === this.selectedRow.WarehouseItemId) {
                return 'table-secondary';
            } else {
                return '';
            }
        },
        keyUpHandlingInItemSearchBar(event) {
            if (!event) {
                return;
            }
            switch (event.code) {
                case 'ArrowDown':
                    this.onClickArrowDown();
                    break;
                case 'ArrowUp':
                    this.onClickArrowUp();
                    break;
            }
        },
        onClickArrowUp(event) {
            if (this.Items.length === 0) {
                return;
            }
            if (!this.selectedRow) {
                this.selectedRow = this.Items[this.Items.length - 1];
            } else if (this.selectedRow) {
                let selectedRowIndex = this.Items.findIndex(this.selectedRow);
                if (selectedRowIndex > 0) {
                    this.selectedRow = this.Items[selectedRowIndex - 1];
                } else if (selectedRowIndex === 0) {
                    this.selectedRow = this.Items[this.Items.length - 1];
                }
            }
        },
        onClickArrowDown(event) {
            if (this.Items.length === 0) {
                return;
            }
            if (!this.selectedRow) {
                this.selectedRow = this.Items[0];
            } else if (this.selectedRow) {
                let selectedRowIndex = this.Items.findIndex(this.selectedRow);
                if (this.Items[selectedRowIndex + 1])
                    this.selectedRow = this.Items[selectedRowIndex + 1];
                else
                    this.selectedRow = this.Items[0];
            }
        },
        onRowDblClicked(item) {
            this.selectedRow = item;
            this.$emit('selectedItem', item);
        },
        searchDebounce: debounce(function (itemName) {
            let vm = this;
            WarehouseItemService.getAll({
                warehouseId: vm.$store.getters.selectedWarehouse.WarehouseId,
                type: vm.itemType,
                query: itemName,
                desc: false,
                sort: 'name',
                skip: 0,
                limit: 20,
                stateeq: null,
                statelt: null,
                stategt: null,
                statelteq: null,
                stategteq: null,
                alert: null,
                category: null
            }).then((Items) => {
                // on item found by barcode, set item selected
                if (Items.length === 1 && Items[0].Item.Barcode === itemName) {
                    vm.item = Items[0];
                    //vm.onInput(items[0]);

                    vm.Items = [];
                } else {
                    vm.Items = Items;
                }
            })
        }, 500),
        onInputChange(search) {
            this.search = search.target && search.target.value ? search.target.value : search;
        },
    },
    watch: {
        search(newV, oldV) {
            this.searchDebounce(newV);
        },
        allowKeyboard() {
            let vm = this;
            if (localStorage.getItem("retailSaleKeyboard") === "banned") {
                localStorage.setItem("retailSaleKeyboard", "allowed");
                vm.isAllowedKeyboard = true;
            } else {
                localStorage.setItem("retailSaleKeyboard", "banned");
                vm.isAllowedKeyboard = false;
            }
        },
    }
}
</script>

<style>
.hg-theme-default .hg-row .hg-button {
    font-size: 20px !important;
}
</style>

<style lang="scss">
.btn-empty {
    color: #969696 !important;
}
</style>
