<template>
    <b-container class="p-0" fluid>
        <b-modal
            :id="`zoom-img-modal${specificKey}`"
            centered hide-header
            body-class="p-0" size="lg"
            hide-footer @cancel="onCancel"
        >
            <b-img-lazy
                fluid-grow
                style="max-height: 100vh"
                :src="ApiHost + '/images/' + selectedImage">
            </b-img-lazy>
        </b-modal>

        <div class="ml-1 cursor-pointer">
            <b-img
                v-if="imageUrl"
                :style="imageStylishName"
                :class="imageClassName"
                @click="enlargeImage(imageUrl)"
                :src="ApiHost + '/images/' + imageUrl"
            >
            </b-img>
        </div>
    </b-container>
</template>

<script>
import {ApiHost} from "../../constants/settings";

export default {
    name: 'ItemImageComponent',
    props: ['imageUrl', 'imageClassName', 'imageStylishName'],
    data() {
        return {
            ApiHost,
            selectedImage: null,
            specificKey: Math.random().toString(16).slice(2)
        }
    },
    methods: {
        enlargeImage(url) {
            this.selectedImage = url
            this.$bvModal.show(`zoom-img-modal${this.specificKey}`)
        },
        onCancel() {
            this.selectedImage = null
            this.$bvModal.hide(`zoom-img-modal${this.specificKey}`)
        }
    },
}
</script>
