import BaseService from "../base/base.service";
import axios from "axios";
import {map, sortBy} from 'underscore'

class SaleRegisterService extends BaseService {
    get modelName() {
        return "RegisterSale";
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            let sale = response.data.Sale;
            let sortedSaleItemsById = sortBy(response.data.SaleItems, 'SaleItemId');
            let sortedSaleItemsDesc = sortedSaleItemsById.reverse();
            return {sale: sale, saleItems: sortedSaleItemsDesc};
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    register({CreatedAt, ClientId, WarehouseId, Items}) {
        return axios.post(this.getCreateUrl(), {
            Sale: {
                CreatedAt: CreatedAt ? this._getDateDDMMYYYFormat(CreatedAt) : null,
                ClientId: ClientId,
                WarehouseId: WarehouseId,
            },
            SaleItems: map(Items, (saleItem) => {
                return {
                    StatePartOperations: map(saleItem.StatePartOperations, (operation) => {
                        return {
                            Id: operation.Id,
                            Name: operation.Name,
                            Quantity: operation.Quantity,
                        }
                    }),
                    CurrencyValue: {
                        Value: saleItem.PriceCurrencyValue.Value,
                        Rate: saleItem.PriceCurrencyValue.Rate,
                        CurrencyId: saleItem.PriceCurrencyValue.CurrencyId,
                    },
                    Quantity: saleItem.Quantity,
                    ItemId: saleItem.Item.Item.ItemId,
                    HasPackQuantity: saleItem.HasPackQuantity,
                    DiscountPercent: saleItem.DiscountPercent || 0,
                    DiscountCurrencyValue: {
                        Value: saleItem.DiscountCurrencyValue.Value,
                        Rate: saleItem.DiscountCurrencyValue.Rate,
                        CurrencyId: saleItem.DiscountCurrencyValue.CurrencyId,
                    },
                    CustomSelects: saleItem.CustomSelects || [],
                    Marks: saleItem.Marks
                };
            })
        }).then(response => response.data).catch(error => {
            throw error
        });
    }
}

export default new SaleRegisterService()
