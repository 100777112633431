<template>
    <b-card :footer-class="includeKeyboard ? 'm-0' : 'm-0 p-0'" body-class="py-1 soft-primary payment-card-body"
            style="height: calc(98vh - 50px); margin-top: 1vh;">

        <ConfirmKKMSaleModal ref="ConfirmKKMSaleModalInSaleForm"/>

        <GlobalEvents
            @keyup="keyUpHandlingInClientPayment"
            @keyup.ctrl.enter="saveSaleWithPaymentCashAmount"/>
        <b-card-body class="p-0">
            <div v-for="item in filterTotalByCurrency" class="w-100 d-flex align-items-center justify-content-between">
                <span class="h6">
                    {{ translate.translate("frequentlyRepeated", "koplate") }}:
                </span>
                <h4 class="fw-bold">
                    {{ item.total | decimal }}
                    {{ item.currency ? item.currency.Name : 'сум' }}
                </h4>
            </div>
            <div v-if="filterTotalByCurrency.length === 0"
                 class="w-100 d-flex align-items-center justify-content-between">
                <span class="h6">
                    {{ translate.translate("frequentlyRepeated", "koplate") }}:
                </span>
                <h4 class="fw-bold">
                    0 сум
                </h4>
            </div>
            <hr/>
            <div class="w-100 d-flex align-items-center justify-content-between">
                <span class="h6">
                    {{ translate.translate("frequentlyRepeated", "vdolg") }}:
                </span>
                <h4 class="fw-bold">
                    {{ debt | decimal }}
                    {{ selectedCurrency ? selectedCurrency.Name : 'сум' }}
                </h4>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-between">
                <span class="h6">
                    {{ translate.translate("frequentlyRepeated", "sdachi") }}:
                </span>
                <h4 class="fw-bold">
                    {{ totalPayBack | decimal }}
                    {{ selectedCurrency ? selectedCurrency.Name : 'сум' }}
                </h4>
            </div>
            <b-form-group class="mt-3">
                <b-form-group>
                    <label>{{ translate.translate("frequentlyRepeated", "oplataNalichnimi") }}</label>
                    <b-input-group>
                        <AppendCurrencyToInput
                            :price-currency-value="activeTab.ClientPayment.PaymentCashCurrencyValue"/>
                        <IMaskPriceInput v-model="activeTab.ClientPayment.PaymentCashCurrencyValue.Value"
                                         @focus="onFocusToPaymentInput(activeTab.ClientPayment.PaymentCashCurrencyValue, 'PaymentCashCurrencyValue')"
                                         @blur="() => { if (!activeTab.ClientPayment.PaymentCashCurrencyValue.Value ) activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0}"
                                         ref="paymentCashAmount"/>
                        <WholeAmountComponent
                            v-if="activeTab.Sale"
                            :debt="debt"
                            :payment-person="activeTab.ClientPayment"
                            :payment-type="'PaymentCashCurrencyValue'"
                            ref="wholeAmountComponentPaymentCashCurrency"
                        />
                    </b-input-group>
                </b-form-group>
            </b-form-group>
            <b-form-group>
                <label>{{ translate.translate("frequentlyRepeated", "oplataVBankovskiyKarti") }}</label>
                <b-input-group>
                    <AppendCurrencyToInput :price-currency-value="activeTab.ClientPayment.PaymentCardCurrencyValue"/>
                    <IMaskPriceInput v-model="activeTab.ClientPayment.PaymentCardCurrencyValue.Value"
                                     @focus="onFocusToPaymentInput(activeTab.ClientPayment.PaymentCardCurrencyValue, 'PaymentCardCurrencyValue')"
                                     @blur="() => { if (!activeTab.ClientPayment.PaymentCardCurrencyValue.Value ) activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0}"/>
                    <WholeAmountComponent
                        v-if="activeTab.Sale"
                        :debt="debt"
                        :payment-person="activeTab.ClientPayment"
                        :payment-type="'PaymentCardCurrencyValue'"
                    />
                </b-input-group>
            </b-form-group>
            <b-form-group>
                <label>{{ translate.translate("frequentlyRepeated", "oplataCherezKartaHumo") }}</label>
                <b-input-group>
                    <AppendCurrencyToInput :price-currency-value="activeTab.ClientPayment.HumoTransferCurrencyValue"/>
                    <IMaskPriceInput v-model="activeTab.ClientPayment.HumoTransferCurrencyValue.Value"
                                     @focus="onFocusToPaymentInput(activeTab.ClientPayment.HumoTransferCurrencyValue, 'HumoTransferCurrencyValue')"
                                     @blur="() => { if (!activeTab.ClientPayment.HumoTransferCurrencyValue.Value ) activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0}"/>
                    <WholeAmountComponent
                        v-if="activeTab.Sale"
                        :debt="debt"
                        :payment-person="activeTab.ClientPayment"
                        :payment-type="'HumoTransferCurrencyValue'"
                    />
                </b-input-group>
            </b-form-group>

            <b-form-group>
                <label>{{ translate.translate("frequentlyRepeated", "oplataDrugimiSposobami") }}</label>
                <b-input-group>
                    <AppendCurrencyToInput :price-currency-value="activeTab.ClientPayment.OtherTransferCurrencyValue"/>
                    <IMaskPriceInput v-model="activeTab.ClientPayment.OtherTransferCurrencyValue.Value"
                                     @focus="onFocusToPaymentInput(activeTab.ClientPayment.OtherTransferCurrencyValue, 'OtherTransferCurrencyValue')"
                                     @blur="() => { if (!activeTab.ClientPayment.OtherTransferCurrencyValue.Value ) activeTab.ClientPayment.OtherTransferCurrencyValue.Value = 0}"/>
                    <WholeAmountComponent
                        v-if="activeTab.Sale"
                        :debt="debt"
                        :payment-person="activeTab.ClientPayment"
                        :payment-type="'OtherTransferCurrencyValue'"
                    />
                </b-input-group>
            </b-form-group>


            <b-form-group>
                <label>{{ translate.translate("frequentlyRepeated", "skidka") }}</label>
                <template v-slot:prepend v-if="typeDiscount === 1">
                    <b-input-group-text>{{ translate.translate("frequentlyRepeated", "skidka") }}
                    </b-input-group-text>
                </template>
                <b-input-group>
                    <AppendCurrencyToInput ref="AppendCurrencyToInput"
                                           v-if="typeDiscount === 0"
                                           :price-currency-value="activeTab.Sale.DiscountCurrencyValue"/>
                    <IMaskPriceInput v-model="activeTab.Sale.DiscountCurrencyValue.Value"
                                     @focus="onFocusToDiscountField"
                                     @blur="() => { if (!activeTab.Sale.DiscountCurrencyValue.Value ) activeTab.Sale.DiscountCurrencyValue.Value = 0}"/>
                    <template v-slot:append>
                        <b-dropdown :text="typeDiscount === 1 ? '%' : ''"
                                    v-model="typeDiscount"
                                    right class="percent">
                            <b-dropdown-item @click="typeDiscount = 0">
                                {{ translate.translate("frequentlyRepeated", "bezprotsentom") }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="typeDiscount = 1">
                                {{ translate.translate("frequentlyRepeated", "sprotsentom") }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-input-group>
            </b-form-group>

            <div class="d-flex justify-content-between mt-4 align-items-center">
                <div>Распечатать чек</div>
                <switches id="tool-mode-switch" v-model="printCheck" theme="custom" class="vue-switcher-small"
                          color="primary" @input="e=>{onChangePrintCheckbox(e)}" :emit-on-mount="false"/>
            </div>
        </b-card-body>
        <template #footer class="p-0">
            <Keyboard v-if="includeKeyboard" ref="KeyBoard"
                      @addNum="addNum"
                      @toNextField="toNextField"
                      @del="del"
                      @clear="clear"
                      @save="saveSale"/>
            <ButtonPayComponent v-else @onClickButton="saveSale"/>
        </template>
    </b-card>
</template>

<script>
import Switches from 'vue-switches';
import AppendCurrencyToInput from "../currency/AppendCurrencyToInput";
import IMaskPriceInput from "../imask/IMaskPriceInput";
import WholeAmountComponent from "./WholeAmountComponent";
import math from "../../plugins/math";
import translate from "../../translation/translate";
import {mapGetters} from "vuex";
import ClientPaymentService from "../../services/client/client.payment.service";
import SaleService from "../../services/sale/sale.service";
import CompleteSaleService from "../../services/sale/complete.sale.service";
import Downloader from "../../downloader";
import GlobalEvents from "vue-global-events";
import Keyboard from "../additional/Keyboard";
import ButtonPayComponent from "../additional/ButtonPayComponent";
import ConfirmKKMSaleModal from "./ConfirmKKMSaleModal";

export default {
    name: "SaleClientPaymentComponent",
    components: {
        ConfirmKKMSaleModal,
        ButtonPayComponent,
        Keyboard, WholeAmountComponent, IMaskPriceInput, AppendCurrencyToInput, Switches, GlobalEvents
    },
    props: {
        activeTab: {
            default: {},
            type: Object
        }
    },
    data() {
        return {
            typeDiscount: 0,
            translate,
            printCheck: true,
            isSubmitted: false,
            focusInField: null,
            includeKeyboard: true,
            debtNotification: false,
        }
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
            defaultEmployee: 'getDefaultEmployee',
            checkedAccessToDebtSale: "getCheckedAccessToDebtSale"
        }),
        filterTotalByCurrency() {
            let vm = this;
            let returnData = [];
            /*            if (vm.currencies.length === 0)
                            return returnData;*/
            vm.currencies.forEach(currency => {
                let filteredItemsByCurrency = vm.activeTab.Items.filter(item => {
                    return currency.CurrencyId === item.PriceCurrencyValue.CurrencyId;
                });
                if (filteredItemsByCurrency.length > 0) {
                    let totalFilteredItems = filteredItemsByCurrency.reduce(function (memo, num) {
                        return +memo + +num.Total;
                    }, 0);
                    returnData.push({
                        currency: currency,
                        items: filteredItemsByCurrency,
                        total: totalFilteredItems
                    });
                }
            });
            let filteredCurrencyNullItems = vm.activeTab.Items.filter(item => {
                return item.PriceCurrencyValue.CurrencyId === null;
            });
            if (filteredCurrencyNullItems.length > 0) {
                let totalFilteredNulItems = filteredCurrencyNullItems.reduce(function (memo, num) {
                    return +memo + +num.Total;
                }, 0);
                returnData.push({currency: null, items: filteredCurrencyNullItems, total: totalFilteredNulItems});
            }
            return returnData;
        },
        totalPayBack() {
            let paymentCashAmount = this.activeTab.ClientPayment.PaymentCashCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.PaymentCashCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value;

            let paymentCardAmount = this.activeTab.ClientPayment.PaymentCardCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.PaymentCardCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value;

            let paymentBankTransferAmount = this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value;

            let humoTransferAmount = this.activeTab.ClientPayment.HumoTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value, +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.HumoTransferCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value;

            let otherTransferAmount = this.activeTab.ClientPayment.OtherTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value, +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.OtherTransferCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value;

            let clientSaleBonusUseAmount = this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value, +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Rate)
                : +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value;

            let totalPayBack = (paymentCashAmount
                + paymentCardAmount
                + paymentBankTransferAmount
                + humoTransferAmount
                + otherTransferAmount
                + clientSaleBonusUseAmount) - this.toPay;

            if (totalPayBack < 0)
                totalPayBack = 0;

            return totalPayBack;
        },
        Total() {
            let total = 0;
            this.activeTab.Items.forEach(item => {
                let value = item.PriceCurrencyValue.Rate ?
                    math.multiply(item.PriceCurrencyValue.Value, item.PriceCurrencyValue.Rate) :
                    item.PriceCurrencyValue.Value;
                total += math.multiply(value, item.Quantity);
            });
            return total;
        },
        toPay() {
            let f = this.$options.filters.division(this.Total);
            let totalWithCurrency = Math.round10(f, -4);

            let discountCurrencyValue = this.activeTab.Sale.DiscountCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(this.activeTab.Sale.DiscountCurrencyValue.Value, this.activeTab.Sale.DiscountCurrencyValue.Rate) : this.activeTab.Sale.DiscountCurrencyValue.Value;

            if (this.typeDiscount === 0) {
                return totalWithCurrency - discountCurrencyValue;
            } else if (this.typeDiscount === 1) {
                return totalWithCurrency - math.multiply(totalWithCurrency, discountCurrencyValue) / 100;
            }
        },
        debt() {
            let paymentCashAmount = this.activeTab.ClientPayment.PaymentCashCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value;

            let paymentCardAmount = this.activeTab.ClientPayment.PaymentCardCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value;

            let paymentBankTransferAmount = this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value;

            let humoTransferAmount = this.activeTab.ClientPayment.HumoTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value, +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value;

            let otherTransferAmount = this.activeTab.ClientPayment.OtherTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value, +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value;

            let clientSaleBonusUseAmount = this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value, +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Rate)
                : +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value;

            let debt = this.toPay - (paymentCashAmount + paymentCardAmount + paymentBankTransferAmount
                + humoTransferAmount + otherTransferAmount + clientSaleBonusUseAmount);

            if (debt < 0)
                debt = 0;
            return debt;
        },
        manualModalValue() {
            return this.$parent.isActiveManualAddProduct
        },
    },
    methods: {
        onFocusToDiscountField() {
            if (!this.activeTab.Sale.DiscountCurrencyValue.Value || this.activeTab.Sale.DiscountCurrencyValue.Value === '0')
                this.activeTab.Sale.DiscountCurrencyValue.Value = ''
        },
        keyUpHandlingInClientPayment(event) {
            if (!event)
                return;
            if (!this.manualModalValue) {
                switch (event.code) {
                    case 'KeyV':
                        this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'PaymentCashCurrencyValue', this.debt);
                        break;
                    case 'KeyB':
                        this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'PaymentCardCurrencyValue', this.debt);
                        break;
                    case 'KeyC':
                        this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'HumoTransferCurrencyValue', this.debt);
                        break;
                    case 'KeyM':
                        this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'OtherTransferCurrencyValue', this.debt);
                        break;
                    case 'Space':
                        this.saveSale();
                        break
                }
            }
        },
        saveSaleWithPaymentCashAmount() {
            if (!this.manualModalValue) {
                this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'PaymentCashCurrencyValue', this.debt);
                this.saveSale();
            }
        },
        onFocusToPaymentInput(PaymentCurrencyValue, field) {
            if (!PaymentCurrencyValue.Value || PaymentCurrencyValue.Value === '0') PaymentCurrencyValue.Value = '';
            if (field) this.focusInField = field;
            else this.focusInField = null;
        },
        clear() {
            this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0;
            this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0;
            this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0;
            this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = 0;
            this.focusInField = null;
        },
        async saveSale() {
            let vm = this;
            if (this.debt > 0 && !this.checkedAccessToDebtSale) {
                return this.$bvToast.toast(translate.translate("frequentlyRepeated", "DosupZakritVDolg"), {
                    title: translate.translate("frequentlyRepeated", "DosupZakritVDolg"),
                    autoHideDelay: 3000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                })
            }

            if (this.debt > 0 && this.debtNotification) {
                let isConfirmed = await vm.$bvModal.msgBoxConfirm(translate.translate("frequentlyRepeated", "ProdajaVdolgNotification"), {
                    title: translate.translate("frequentlyRepeated", "ProdajaVdolgNotification"),
                    okTitle: translate.translate("frequentlyRepeated", "Podtverjdayu"),
                    cancelTitle: translate.translate("frequentlyRepeated", "NePodtverjdayu"),
                    buttonSize: 'lg'
                }).catch(errHandler => {
                });
                if (!isConfirmed)
                    return
            }

            if (!vm.activeTab.Sale.SaleId || vm.isSubmitted)
                return;
            vm.isSubmitted = true;

            window.dispatchEvent(new CustomEvent('sale-is-submitted', {
                detail: {
                    isSubmitted: vm.isSubmitted
                }
            }));

            if (vm.totalPayBack > 0 && vm.activeTab.ClientPayment.PaymentCashCurrencyValue.Value > 0) {
                vm.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = vm.activeTab.ClientPayment.PaymentCashCurrencyValue.Value - vm.totalPayBack;
            }
            let data = {
                Description: '',
                ClientId: this.activeTab.Sale.Client.ClientId,
                CreatedAt: new Date(this.activeTab.Sale.CreatedAt),
                PaymentCashCurrencyValue: this.activeTab.ClientPayment.PaymentCashCurrencyValue,
                PaymentCardCurrencyValue: this.activeTab.ClientPayment.PaymentCardCurrencyValue,
                PaymentBankTransferCurrencyValue: this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue,
                HumoTransferCurrencyValue: this.activeTab.ClientPayment.HumoTransferCurrencyValue,
                OtherTransferCurrencyValue: this.activeTab.ClientPayment.OtherTransferCurrencyValue,
                ClientPaymentId: this.activeTab.Sale.ClientPayment ? this.activeTab.Sale.ClientPayment.ClientPaymentId : null
            }
            let res = null

            if (this.activeTab.Sale.ClientPayment) {
                res = await ClientPaymentService.edit(data)
            } else {
                res = await ClientPaymentService.create(data);
            }
            vm.activeTab.Sale.IsApproved = true;
            vm.activeTab.Sale.ClientPayment = res;

            SaleService.edit(vm.activeTab.Sale).then(async sale => {

                if (this.typeDiscount === 1) {
                    let bb = vm.activeTab.Sale.DiscountCurrencyValue.Value / 100;
                    let total = vm.Total;
                    if (vm.selectedCurrency) {
                        total = Math.round10(this.$options.filters.division(vm.Total), -4);
                    }
                    vm.activeTab.Sale.DiscountCurrencyValue.Value = math.multiply(total, bb);
                }

                await vm.completeSale();
                if (vm.printCheck) {
                    await Downloader.downloadSalePdf(sale, true);
                }

                await vm.$refs.ConfirmKKMSaleModalInSaleForm.sendSaleToEPos(sale.SaleId)

                vm.isSubmitted = false;

                vm.$emit('successApproved');

            }).catch(err => {
                vm.isSubmitted = false;
                vm.$bvToast.toast(translate.translate("frequentlyRepeated", "VozniklaOshibka"), {
                    title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
                    autoHideDelay: 4000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'danger'
                });
            }).finally(() => {
                window.dispatchEvent(new CustomEvent('sale-is-submitted', {
                    detail: {
                        isSubmitted: vm.isSubmitted
                    }
                }));
            });
        },
        async completeSale() {
            await CompleteSaleService.edit(this.activeTab.Sale.SaleId, {
                EmployeeId: this.activeTab.Sale.Employee ? this.activeTab.Sale.Employee.EmployeeId : (this.defaultEmployee ? this.defaultEmployee.EmployeeId : null),
                Description: this.activeTab.Sale.Description,
                SaleNumber: this.activeTab.Sale.SaleNumber,
                ClientPaymentId: this.activeTab.Sale.ClientPayment ? this.activeTab.Sale.ClientPayment.ClientPaymentId : null,
                DiscountCurrencyValue: {
                    Value: this.activeTab.Sale.DiscountCurrencyValue.Value,
                    Rate: this.activeTab.Sale.DiscountCurrencyValue.Rate,
                    CurrencyId: this.activeTab.Sale.DiscountCurrencyValue.CurrencyId,
                },
                ClientSaleBonusUseCurrencyValue: this.activeTab.Sale.ClientSaleBonusUseCurrencyValue,
                ClientSaleBonusCurrencyValue: this.activeTab.Sale.ClientSaleBonusCurrencyValue,
                RegistrationType: 1,
            });
        },
        toNextField() {
            let vm = this;
            if (vm.focusInField === 'PaymentCashCurrencyValue') {
                vm.focusInField = 'PaymentCardCurrencyValue'
            } else if (vm.focusInField === 'PaymentCardCurrencyValue') {
                vm.focusInField = 'HumoTransferCurrencyValue'
            } else {
                vm.focusInField = 'PaymentCashCurrencyValue'
            }
        },
        del() {
            if (this.focusInField && this.focusInField === 'PaymentCardCurrencyValue') {
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value.length - 1);
            } else if (this.focusInField && this.focusInField === 'HumoTransferCurrencyValue') {
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value.length - 1);
            } else if (this.focusInField && this.focusInField === 'OtherTransferCurrencyValue') {
                this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value.length - 1);
            } else {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value.length - 1);
            }
        },
        addNum(key) {
            if (this.focusInField && this.focusInField === 'PaymentCardCurrencyValue') {
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = String(this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value + key).slice(0);
            } else if (this.focusInField && this.focusInField === 'HumoTransferCurrencyValue') {
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = String(this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value + key).slice(0);
            } else if (this.focusInField && this.focusInField === 'OtherTransferCurrencyValue') {
                this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = String(this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value + key).slice(0);
            } else {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = String(this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value + key).slice(0);
            }
        },
        reCalculateClientPayments() {
            if (+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value > 0 && +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value === 0 && +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value === 0) {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = 0;
                this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'PaymentCashCurrencyValue', this.debt);
            } else if (+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value === 0 && +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value > 0 && +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value === 0) {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = 0;
                this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'PaymentCardCurrencyValue', this.debt);
            } else if (+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value === 0 && +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value === 0 && +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value > 0) {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = 0;
                this.$refs.wholeAmountComponentPaymentCashCurrency.setValueTotalWithExternal(this.activeTab.ClientPayment, 'HumoTransferCurrencyValue', this.debt);
            } else {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0;
                this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value = 0;
            }
        },
        onChangePrintCheckbox(e) {
            if (typeof e == 'boolean')
                localStorage.setItem('printCheck', e)
        }
    },
    mounted() {
        window.addEventListener('include-keyboard-key-localstorage-changed', (event) => {
            this.includeKeyboard = event.detail.storage !== 'false';
        });
        window.addEventListener('debt-notification-localstorage-changed', (event) => {
            this.debtNotification = event.detail.storage === 'true';
        });

        this.printCheck = localStorage.getItem('printCheck') !== 'false';
        this.includeKeyboard = localStorage.getItem('includeKeyboard') !== 'false';
        this.debtNotification = localStorage.getItem('debtNotification') === 'true';
    }
}
</script>

<style scoped>

</style>
