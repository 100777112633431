<template>
    <b-row style="font-size: 90%">
        <GlobalEvents
            @keyup.113="openManualAddItemModal"/>
        <FavoriteProducts @selectedProduct="onSelectedItemByFavorite"/>
        <b-col class="px-2" cols="12" style="height: 50px">
            <SaleNavbarComponent :sale-tab="SaleTab" @resetNewTab="resetNewTab"/>
        </b-col>
        <b-col class="firstBlock px-2" lg="8" xl="9">
            <b-card class="soft-primary mt-2" body-class="p-0">
                <div class="w-100 px-3 d-xl-flex align-items-xl-center justify-content-between">
                    <div class="d-xl-flex">
                        <div class="d-xl-flex align-items-center px-2 my-2 my-lg-0">
                            <small class="mr-2">Склад:</small>
                            <WarehouseSearchElement/>
                        </div>
                        <div class="d-xl-flex align-items-center px-2 my-2 my-lg-0">
                            <small class="mr-2">Клиент:</small>
                            <ClientSearchElement v-model="activeTab.Sale.Client"/>
                        </div>
                    </div>
                    <div class="d-xl-flex">
                        <div class="d-xl-flex align-items-center px-2 my-2 my-lg-0" v-if="isCheckedSaleEmployee">
                            <small class="mr-2">Сотрудник:</small>
                            <EmployeeSearchElement v-model="activeTab.Sale.Employee"/>
                        </div>
                        <div class="text-right my-2 my-lg-0">
                            <b-button size="sm" class="rounded-2 my-primary" @click="openManualAddItemModal">
                                Добавить товар вручную
                                <b-icon-plus class="cursor-pointer" font-scale="1.2"
                                             variant="black"></b-icon-plus>
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card footer-class="p-0 d-flex bg-white"
                    class="shadow"
                    body-class="p-0 m-0"
                    style="height:calc(98vh - 93px); margin-top: 10px">
                <SaleBasketListComponent :Sale="activeTab.Sale" :Items="activeTab.Items" ref="SaleBasketListComponent"
                                         @reloadSale="reCalculateClientPayment(activeTab.Sale.SaleId)"/>
            </b-card>
        </b-col>
        <b-col class="px-2" lg="4" xl="3">
            <SaleClientPaymentComponent :activeTab="activeTab"
                                        @successApproved="onSuccessApproved"
                                        ref="SaleClientPaymentComponent"/>
        </b-col>
        <ManualAddProduct title="Новая продажа" ref="manualAddProduct" @addProduct="addNewItem"/>
    </b-row>
</template>

<script>
import {mapGetters} from "vuex";

import WarehouseSearchElement from "../../../components/searchElements/WarehouseSearchElement";
import ClientSearchElement from "../../../components/searchElements/ClientSearchElement";
import SaleClientPaymentComponent from "../../../components/sale/SaleClientPaymentComponent";
import SaleBasketListComponent from "../../../components/sale/SaleBasketListComponent";
import translate from "../../../translation/translate";
import WarehouseItemBarcodeService from "../../../services/warehouse/warehouse.item.barcode.service";
import WarehouseItemService from "../../../services/warehouse/warehouse.item.service";
import SaleItemService from "../../../services/sale/sale.item.service";
import ClientPaymentService from "../../../services/client/client.payment.service";
import SaleRegisterService from "../../../services/sale/sale.register.service";
import ManualAddProduct from "../../../components/sale/ManualAddProduct";
import HotKeysInfo from "../../../components/additional/HotKeysInfo";
import GlobalEvents from "vue-global-events";
import SaleNavbarComponent from "../../../components/sale/SaleNavbarComponent";
import FavoriteProducts from "../../../components/sale/FavoriteProducts";
import {EventBus} from "../../../plugins/global.events.bus";
import math from "../../../plugins/math";
import EmployeeSearchElement from "../../../components/searchElements/EmployeeSearchElement";

export default {
    name: "NewSale",
    components: {
        EmployeeSearchElement,
        FavoriteProducts,
        SaleNavbarComponent,
        GlobalEvents,
        HotKeysInfo,
        SaleBasketListComponent,
        SaleClientPaymentComponent, ClientSearchElement, WarehouseSearchElement, ManualAddProduct
    },
    data() {
        return {
            translate,
            SaleTab: [],
            focusInField: null,
            isSubmitted: false,
            selectedRow: null,
            isCheckedSaleEmployee: false
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            defaultClient: 'getDefaultClient',
            defaultEmployee: 'getDefaultEmployee',
            selectedWarehouse: 'selectedWarehouse',
            getCheckedItemStateMoreThanZero: 'getCheckedItemStateMoreThanZero',
            isCheckedSaleWithoutCurrency: "getCheckedSaleWithoutCurrency",
            isAllowedEditSale: "isAllowedEditSale",
            currencies: "getAllCurrencies",
            compareSalePriceToPurchasePrice: "getCompareSalePriceToPurchasePrice",
        }),
        activeTab() {
            return this.SaleTab.find(tab => tab.IsActive);
        },
        isActiveManualAddProduct() {
            return this.$refs.manualAddProduct.isShown;
        }
    },
    methods: {
        openManualAddItemModal() {
            this.$root.$emit('bv::show::modal', 'manual-add-modal', '#btnShow')
            this.$nextTick(function () {
                this.$refs.manualAddProduct.focus()
            })
        },
        onSuccessApproved() {
            let findIndex = this.SaleTab.findIndex(tab => tab.Sale.SaleId === this.activeTab.Sale.SaleId);
            this.SaleTab.splice(findIndex, 1);
            if (this.SaleTab.length > 0) {
                this.SaleTab[0].IsActive = true;
            } else {
                this.resetNewTab();
            }
            this.$bvToast.toast(translate.translate("frequentlyRepeated", "OplachenoUspeshno"), {
                title: translate.translate("frequentlyRepeated", "uspeshno"),
                autoHideDelay: 5000,
                toaster: 'b-toaster-bottom-left',
                variant: 'success'
            })
        },
        resetNewTab() {
            this.SaleTab.push({
                Sale: {
                    CreatedAt: new Date(),
                    Client: this.defaultClient,
                    Employee: this.defaultEmployee,
                    IsApproved: false,
                    Description: '',
                    SaleNumber: null,
                    Warehouse: this.selectedWarehouse,
                    DiscountCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    ClientSaleBonusUseCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    ClientSaleBonusCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                },
                Items: [],
                ClientPayment: {
                    PaymentCashCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    PaymentCardCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    PaymentBankTransferCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    HumoTransferCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    OtherTransferCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        SystemValue: 0,
                        Value: 0
                    },
                    Description: '',
                    Client: null,
                    CreatedAt: new Date(),
                    ClientPaymentId: null,
                    SystemTotalValue: null,
                    Username: null,
                },
                IsActive: true,
            });
        },
        onBarcodeScanned(barcode) {
            let vm = this;
            let marks = null;
            if (!barcode || this.$refs.SaleClientPaymentComponent.isSubmitted)
                return;
            if (barcode.length > 14) {
                marks = barcode;
                // markirovkani shtrix kodini olamiz
                barcode = barcode.slice(0, 14);
                // markirovkani 0 larini kesib tashimiz
                barcode = barcode.replace(/^0+|$/g, '');
            }
            WarehouseItemBarcodeService.get({
                barcode: barcode,
                warehouseId: vm.selectedWarehouse.WarehouseId,
            }).then((item) => {
                let NewItem = {
                    PriceCurrencyValue: item.Item.PriceCurrencyValue,
                    Quantity: 1,
                    Item: item,
                    DiscountCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    Marks: marks
                }
                let existItem = vm.activeTab.Items.find(item => item.WarehouseItem.Item.ItemId === NewItem.Item.Item.ItemId);

                if (existItem && vm.getCheckedItemStateMoreThanZero && (NewItem.Item.State < existItem.Quantity + 1)) {
                    return this.$bvToast.toast(translate.translate("frequentlyRepeated", "RedaktirovatNeUdalasTovaraNet"), {
                        title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
                        autoHideDelay: 5000,
                        toaster: 'b-toaster-bottom-left',
                        variant: 'warning'
                    });
                }

                //agar $ dagi tovarlarini ham so'mda bo'lsa sotmoqchi narx valyutasini so'mga o'girib beramiz
                if (this.currencies.length > 0 && NewItem.Item.Item.PriceCurrencyValue && NewItem.Item.Item.PriceCurrencyValue.CurrencyId) {
                    let currentPriceCurrency = this.currencies.find(currency => currency.CurrencyId === NewItem.Item.Item.PriceCurrencyValue.CurrencyId);
                    if (NewItem.Item.Item.PriceCurrencyValue.Currency && this.isCheckedSaleWithoutCurrency) {
                        NewItem.PriceCurrencyValue.Currency = null;
                        NewItem.PriceCurrencyValue.CurrencyId = null;
                        NewItem.PriceCurrencyValue.CurrencyValueId = null;
                        NewItem.PriceCurrencyValue.Rate = null;
                        NewItem.PriceCurrencyValue.Value = math.multiply(+NewItem.Item.Item.PriceCurrencyValue.Value, currentPriceCurrency ? +currentPriceCurrency.Rate : NewItem.Item.Item.PriceCurrencyValue.Currency.Rate);
                    }
                }

                vm.addNewItem(NewItem);
            }).catch(error => {
                vm.getWarehouseItemBySku(barcode);
            });
        },
        getWarehouseItemBySku(barcode) {
            if (barcode.length !== 13)
                return;

            let sku = barcode.slice(2, 7);

            if (barcode.startsWith('22')) {
                sku = barcode.slice(2, 5);
                sku = sku.replace(/^0+|$/g, '');
            }

            let weightMilligram = barcode.slice(7);
            weightMilligram = weightMilligram.replace(/^0+|$/g, '');

            WarehouseItemService.getAll({
                warehouseId: this.selectedWarehouse.WarehouseId,
                type: 1,
                query: '',
                sku: sku,
                desc: true,
                sort: '',
                skip: 0,
                limit: null,

                stateeq: null,
                statelt: null,
                stategt: null,
                statelteq: null,
                stategteq: null,
                alert: null,
                category: null,
                tag: null,
            }).then(warehouseItems => {
                if (warehouseItems.length === 0) {
                    return this.$bvToast.toast('Mahsulot topilmadi', {
                        title: 'Mahsulot topilmadi',
                        autoHideDelay: 5000,
                        toaster: 'b-toaster-bottom-left',
                        variant: 'warning'
                    });
                }
                let item = warehouseItems[0];
                let weightKg = (weightMilligram / 10000).toString();

                //weightKg = weightKg.substr(0, weightKg.length - 1);

                if (item.Item.Measurement.toLocaleLowerCase() === 'шт') {
                    weightKg = 1
                }
                let NewItem = {
                    PriceCurrencyValue: item.Item.PriceCurrencyValue,
                    Quantity: weightKg,
                    Item: item,
                    DiscountCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    }
                }
                this.addNewItem(NewItem);
            }).catch(err => {
                this.$bvToast.toast('Mahsulot topilmadi', {
                    title: 'Mahsulot topilmadi',
                    autoHideDelay: 5000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                });
            });
        },
        addNewItem(NewItem) {
            let vm = this;
            if (!this.activeTab.Sale.Client) {
                return vm.$bvToast.toast(translate.translate("frequentlyRepeated", "KlientNeVibran"), {
                    title: translate.translate("frequentlyRepeated", "KlientPoUmolchaniyu"),
                    autoHideDelay: 5000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                });
            }
            if (vm.getCheckedItemStateMoreThanZero && (NewItem.Item.State < +NewItem.Quantity)) {
                if (vm.$refs.manualAddProduct)
                    vm.$refs.manualAddProduct.setSubmittedValue(false);
                return this.$bvToast.toast(translate.translate("frequentlyRepeated", "RedaktirovatNeUdalasTovaraNet"), {
                    title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
                    autoHideDelay: 5000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                });
            }

            if (this.compareSalePriceToPurchasePrice) {
                if (NewItem.Item.LastPurchasePriceCurrencyValue && NewItem.Item.LastPurchasePriceCurrencyValue.SystemValue > NewItem.PriceCurrencyValue.SystemValue) {
                    this.$bvToast.toast('Mahsulot sotuv narxi tannarxidan arzon sotilyapti!', {
                        title: 'Ogohlantrish',
                        autoHideDelay: 5000,
                        toaster: 'b-toaster-bottom-left',
                        variant: 'warning'
                    });
                }
            }

            if (vm.isSubmitted)
                return;
            vm.isSubmitted = true;
            if (vm.activeTab.Sale.SaleId) {
                let existItem = vm.activeTab.Items.find(item => item.WarehouseItem.Item.ItemId === NewItem.Item.Item.ItemId);
                if (existItem && !NewItem.Marks) {
                    existItem.Quantity = +existItem.Quantity + +NewItem.Quantity;
                    SaleItemService.edit(existItem.SaleItemId, {
                        sale: vm.activeTab.Sale,
                        saleItem: existItem
                    }).then((updatedItem) => {
                        vm.isSubmitted = false;
                        let findIndex = vm.activeTab.Items.findIndex(item => item.SaleItemId === updatedItem.SaleItemId);
                        vm.$set(vm.activeTab.Items, findIndex, updatedItem);
                        if (vm.$refs.manualAddProduct)
                            vm.$refs.manualAddProduct.clear();
                        vm.$refs.SaleClientPaymentComponent.reCalculateClientPayments();

                        vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                            title: translate.translate("frequentlyRepeated", "uspeshno"),
                            autoHideDelay: 300,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'success'
                        });
                    });
                } else {
                    SaleItemService.create({sale: this.activeTab.Sale, saleItem: NewItem}).then(saleItem => {
                        vm.isSubmitted = false;
                        vm.activeTab.Items.unshift(saleItem);
                        if (vm.$refs.manualAddProduct)
                            vm.$refs.manualAddProduct.clear();
                        vm.$refs.SaleClientPaymentComponent.reCalculateClientPayments();

                        vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                            title: translate.translate("frequentlyRepeated", "uspeshno"),
                            autoHideDelay: 300,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'success'
                        });
                    }).catch(err => {
                        vm.$bvToast.toast(translate.translate("frequentlyRepeated", "VozniklaOshibka"), {
                            title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
                            autoHideDelay: 4000,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'danger'
                        });
                    });
                }
            } else {
                SaleRegisterService.register({
                    CreatedAt: vm.activeTab.Sale.CreatedAt,
                    ClientId: vm.activeTab.Sale.Client.ClientId,
                    WarehouseId: vm.selectedWarehouse.WarehouseId,
                    Items: [NewItem],
                }).then((response) => {
                    vm.isSubmitted = false;
                    vm.activeTab.Sale = response.Sale;
                    vm.activeTab.Items = response.SaleItems;
                    if (vm.$refs.manualAddProduct)
                        vm.$refs.manualAddProduct.clear();
                    vm.$refs.SaleClientPaymentComponent.reCalculateClientPayments();
                    vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                        title: translate.translate("frequentlyRepeated", "uspeshno"),
                        autoHideDelay: 300,
                        toaster: 'b-toaster-bottom-left',
                        variant: 'success'
                    });
                    vm.$store.dispatch('loadActivePackage');
                }).catch(err => {
                    vm.$bvToast.toast(translate.translate("frequentlyRepeated", "VozniklaOshibka"), {
                        title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
                        autoHideDelay: 4000,
                        toaster: 'b-toaster-bottom-left',
                        variant: 'danger'
                    });
                });
            }
        },
        async loadSaleRegister(SaleId) {
            let vm = this;
            return new Promise((resolve, reject) => {
                SaleRegisterService.get(SaleId).then(async ({sale, saleItems}) => {
                    vm.activeTab.Sale = sale;
                    vm.activeTab.Items = saleItems;
                    resolve({sale: sale, saleItems: saleItems});
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        async loadClientPayment(ClientPaymentId) {
            let ClientPayment = await ClientPaymentService.get(ClientPaymentId);

            if (ClientPayment) {
                let paymentCashCurrencyType = this.getPaymentByType(ClientPayment.Payments, 1);
                this.activeTab.ClientPayment.PaymentCashCurrencyValue = {
                    Currency: paymentCashCurrencyType.Money.Currency,
                    CurrencyId: paymentCashCurrencyType.Money.Currency ? paymentCashCurrencyType.Money.Currency.Id : null,
                    CurrencyValueId: null,
                    Rate: paymentCashCurrencyType.Money.Currency ? paymentCashCurrencyType.Money.Currency.Rate : null,
                    Value: paymentCashCurrencyType.Money.Value,
                }

                let paymentCardCurrencyValue = this.getPaymentByType(ClientPayment.Payments, 2);
                this.activeTab.ClientPayment.PaymentCardCurrencyValue = {
                    Currency: paymentCardCurrencyValue.Money.Currency,
                    CurrencyId: paymentCardCurrencyValue.Money.Currency ? paymentCardCurrencyValue.Money.Currency.Id : null,
                    CurrencyValueId: null,
                    Rate: paymentCardCurrencyValue.Money.Currency ? paymentCardCurrencyValue.Money.Currency.Rate : null,
                    Value: paymentCardCurrencyValue.Money.Value,
                }

                let humoTransferCurrencyValue = this.getPaymentByType(ClientPayment.Payments, 3);
                this.activeTab.ClientPayment.HumoTransferCurrencyValue = {
                    Currency: humoTransferCurrencyValue.Money.Currency,
                    CurrencyId: humoTransferCurrencyValue.Money.Currency ? humoTransferCurrencyValue.Money.Currency.Id : null,
                    CurrencyValueId: null,
                    Rate: humoTransferCurrencyValue.Money.Currency ? humoTransferCurrencyValue.Money.Currency.Rate : null,
                    Value: humoTransferCurrencyValue.Money.Value,
                }

                let paymentBankTransferCurrencyValue = this.getPaymentByType(ClientPayment.Payments, 4);
                this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue = {
                    Currency: paymentBankTransferCurrencyValue.Money.Currency,
                    CurrencyId: paymentBankTransferCurrencyValue.Money.Currency ? paymentBankTransferCurrencyValue.Money.Currency.Id : null,
                    CurrencyValueId: null,
                    Rate: paymentBankTransferCurrencyValue.Money.Currency ? paymentBankTransferCurrencyValue.Money.Currency.Rate : null,
                    Value: paymentBankTransferCurrencyValue.Money.Value,
                }

                let otherTransferCurrencyValue = this.getPaymentByType(ClientPayment.Payments, 0);
                this.activeTab.ClientPayment.OtherTransferCurrencyValue = {
                    Currency: otherTransferCurrencyValue.Money.Currency,
                    CurrencyId: otherTransferCurrencyValue.Money.Currency ? otherTransferCurrencyValue.Money.Currency.Id : null,
                    CurrencyValueId: null,
                    Rate: otherTransferCurrencyValue.Money.Currency ? otherTransferCurrencyValue.Money.Currency.Rate : null,
                    Value: otherTransferCurrencyValue.Money.Value,
                }
            }
        },
        getPaymentByType(payments, type) {
            return payments.find(payment => payment.MovementType === type)
        },
        reCalculateClientPayment(saleId) {
            this.loadSaleRegister(saleId);
            this.$refs.SaleClientPaymentComponent.reCalculateClientPayments();
        },
        onSelectedItemByFavorite(warehouseItem) {
            let NewItem = {
                PriceCurrencyValue: warehouseItem.Item.PriceCurrencyValue,
                Quantity: 1,
                Item: warehouseItem,
                DiscountCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                Marks: null
            }
            this.addNewItem(NewItem);
        }
    },
    created() {
        if (this.$route.query.saleId && this.isAllowedEditSale) {
            this.loadSaleRegister(+this.$route.query.saleId).then(async res => {
                if (res.sale.ClientPayment)
                    await this.loadClientPayment(res.sale.ClientPayment.ClientPaymentId);
            }).catch(err => {
            });
        }
        this.resetNewTab();
    },
    mounted() {
        this.$barcodeScanner.init((barcode) => {
            this.onBarcodeScanned(barcode)
            EventBus.$emit('BARCODE_SCANNED', barcode);
        })

        window.addEventListener('checked-sale-employee-localstorage-changed', (event) => {
            this.isCheckedSaleEmployee = event.detail.storage === 'true';
        });
        this.isCheckedSaleEmployee = localStorage.getItem('isCheckedSaleEmployee') === 'true';
    },
    destroyed() {
        this.$barcodeScanner.destroy()
    },
}
</script>

<style lang="scss">

</style>
