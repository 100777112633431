<template>
    <b-modal id="ConfirmKKMSaleModal" hide-footer no-close-on-backdrop size="xl" hide-header
             v-model="isShownConfirmKKMModal">
        <b-row class="mb-4">
            <b-col cols="10">
                <h2>{{ translation.translate("frequentlyRepeated", "XotiteProvestiProdajusKKM") }}</h2>
            </b-col>
            <b-col cols="2">
                <b-button-close @click="onCancel"/>
            </b-col>
        </b-row>
        <b-card>
            <b-card-title class="h2">Оплата через QR code</b-card-title>
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <input type="checkbox" id="kkmTypePayme" value="0" @change="onCheckedPayme"
                           :checked="payType === '0'"/>
                    <label for="kkmTypePayme">
                        <img width="200px" height="88px" src="@/assets/images/payme.jpg"/>
                    </label>
                </div>
                <div>
                    <input type="checkbox" id="kkmTypeClick" value="1" @change="onCheckedClick"
                           :checked="payType === '1'"/>
                    <label for="kkmTypeClick">
                        <img width="200px" src="@/assets/images/click-logo.jpg"/>
                    </label>
                </div>
                <div>
                    <input type="checkbox" id="kkmTypeUzum" value="2" @change="onCheckedUzum"
                           :checked="payType === '2'"/>
                    <label for="kkmTypeUzum">
                        <img width="200px" src="@/assets/images/uzum_logo.png"/>
                    </label>
                </div>
            </div>
            <div v-if="!qrToken" class="h5 d-flex align-items-center mt-4 justify-content-center">
                <div>
                    <i class="simple-icon-info text-info mr-1"></i>
                    Выберите тип оплаты и отсканируйте QR code
                </div>
            </div>
            <div v-if="qrToken" class="text-center">
                <img width="200px" src="@/assets/images/qrcode_checked.png"/>
                <h4>Успешно отсканирован</h4>
            </div>
        </b-card>
        <b-button-toolbar class="mt-5 float-right">
            <b-button-group class="mx-1">
                <b-button @click="onCancel" type="button" size="lg">
                    <span style="font-size: 25px">{{
                            translation.translate("frequentlyRepeated", "NePodtverjdayu")
                        }}</span>
                </b-button>
            </b-button-group>
            <b-button-group class="mx-1">
                <b-button
                    :disabled="submitted"
                    variant="primary"
                    size="lg"
                    @click="onConfirm">
                    <b-spinner v-if="submitted" small></b-spinner>
                    <span style="font-size: 25px">{{
                            translation.translate("frequentlyRepeated", "Podtverjdayu")
                        }}</span>
                </b-button>
            </b-button-group>
        </b-button-toolbar>
    </b-modal>
</template>

<script>
import translation from '@/translation/translate';
import {mapGetters} from "vuex";
import EPosSaleService from "../../services/epos/epossale.service";
import EPosExternalApiService from "../../services/epos/EPosExternalApiService";
import printJS from "print-js";
import translate from "../../translation/translate";
import {EventBus} from "../../plugins/global.events.bus";

export default {
    name: "ConfirmKKMSaleModal",
    data() {
        return {
            translation: translation,
            submitted: false,
            payType: null,
            qrToken: null,
            resolvePromise: undefined,
            rejectPromise: undefined,
            saleId: '',
            isShownConfirmKKMModal: false
        }
    },
    computed: {
        ...mapGetters({
            statusEPos: 'getStatusEPos',
            organization: "getOrganization",
        })
    },
    methods: {
        clear() {
            this.qrToken = null
            this.saleId = ''
            this.payType = null
            this.resolvePromise = undefined
            this.rejectPromise = undefined
        },
        async sendSaleToEPos(SaleId) {
            let vm = this
            vm.saleId = SaleId;
            if (vm.organization.KkmStatus && vm.statusEPos) {
                this.$bvModal.show('ConfirmKKMSaleModal')
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve
                    this.rejectPromise = reject
                })
            }
        },
        async onConfirm() {
            if (this.qrToken && this.payType === null) {
                return this.$bvToast.toast("Выберите тип оплата", {
                    title: "Возникла ошибка",
                    autoHideDelay: 5000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                })
            }
            if (!this.qrToken && this.payType) {
                return this.$bvToast.toast("Отсканируйте QR code", {
                    title: "Возникла ошибка",
                    autoHideDelay: 5000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                })
            }
            this.resolvePromise(true)
            await this.sendToApi(this.saleId)
            this.$bvModal.hide('ConfirmKKMSaleModal')
            this.clear()
        },
        async sendToApi(SaleId) {
            let payTypeParams = {
                payType: this.payType || null,
                qrToken: this.qrToken || null
            }
            await EPosSaleService.get(SaleId, payTypeParams).then(async res => {
                await EPosExternalApiService.sendSale(res.data.data).then(async response => {
                    if (response && response.data && !response.data.error) {
                        await EPosSaleService.edit(SaleId, {
                            info: response.data.info,
                            paycheck: response.data.paycheck
                        }).then(async () => {
                            printJS({
                                printable: response.data.paycheck,
                                type: "pdf",
                                base64: true
                            });
                        }).catch(error => {
                        });
                    } else {
                        this.$bvToast.toast(response ? response.data.message : translation.translate("frequentlyRepeated", "oshibka"), {
                            title: translate.translate("frequentlyRepeated", "OshibkaPriOtpravlenie"),
                            autoHideDelay: 5000,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'danger'
                        });
                    }
                }).catch(error => {
                })
            }).catch(err => {
            });
        },
        onCancel() {
            this.resolvePromise(false)
            this.$bvModal.hide('ConfirmKKMSaleModal')
            this.clear()
        },
        onCheckedPayme(event) {
            if (event.target.checked) {
                this.payType = '0'
            } else {
                this.payType = null
            }
        },
        onCheckedClick(event) {
            if (event.target.checked) {
                this.payType = '1'
            } else {
                this.payType = null
            }
        },
        onCheckedUzum(event) {
            if (event.target.checked) {
                this.payType = '2'
            } else {
                this.payType = null
            }
        },
        onBarcodeScanned(qrToken) {
            if (qrToken && this.isShownConfirmKKMModal) {
                this.qrToken = qrToken
            }
        }
    },
    created() {
        EventBus.$on('BARCODE_SCANNED', barcode => {
            this.onBarcodeScanned(barcode)
        })
    }
}
</script>

<style scoped>
input[type="checkbox"][id^="kkmType"] {
    display: none;
}

label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
}

label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
}

label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

:checked + label {
    border-color: #ddd;
}

:checked + label:before {
    content: "✓";
    background-color: green;
    transform: scale(1);
}

:checked + label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
}
</style>
