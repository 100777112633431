<template>
    <b-navbar class="h-100 my-primary px-3">
        <b-modal id="SaleSettingsModal" hide-footer title="Настройки" size="lg">
            <SaleSettings/>
        </b-modal>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mr-auto">
                <b-nav-item
                    v-for="(tab, index) in SaleTab" :key="index"
                    :class="tab.IsActive ? 'border-bottom' : ''"
                    @click="changeTab(tab)"
                    class="mx-2">
                    <div class="text-white">
                        {{ tab.Sale.Client ? tab.Sale.Client.Name : 'Продажа ' + `${index + 1}` }}
                        <b-icon-x-circle @click="deleteTab(index, tab)" variant="white" class="sale-del-icon"
                                         font-scale="0.9"></b-icon-x-circle>
                    </div>
                </b-nav-item>
                <b-button @click="addNewSaleTab" class="mx-2 p-2 my-primary" size="sm">
                    <b-icon-plus-lg variant="white"></b-icon-plus-lg>
                </b-button>
            </b-navbar-nav>
            <div v-if="$parent.$refs.SaleBasketListComponent && $parent.$refs.SaleBasketListComponent.typingNumbers"
                 class="h5 text-white ml-5">
                <span>Введенный номер: {{ $parent.$refs.SaleBasketListComponent.typingNumbers | decimal }}</span>
            </div>
            <b-navbar-nav class="mx-auto"><h5 class="text-white cursor-pointer" @click="goToWebSite">HIPPO.UZ</h5>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto d-flex align-items-center">
                <b-nav-item>
                    <b-icon-gear class="text-white font-weight-bold mr-4" @click="$bvModal.show('SaleSettingsModal')"/>
                </b-nav-item>
                <b-nav-item>
                    <b-icon-tags-fill class="text-white font-weight-bold"
                                      @click="$bvModal.show('FavoriteProductsModal')"/>
                </b-nav-item>
                <b-nav-item>
                    <HotKeysInfo/>
                </b-nav-item>
                <b-nav-item>
                    <b-nav-item-dropdown right>
                        <template v-slot:button-content>
                            <span class="text-white">{{ currentUser }}</span>
                        </template>
                        <b-dropdown-item @click="logout">{{
                                translate.translate("frequentlyRepeated", "vixod")
                            }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import translate from "../../translation/translate";
import SaleService from "../../services/sale/sale.service";
import HotKeysInfo from "../additional/HotKeysInfo";
import {mapActions, mapGetters} from "vuex";
import SaleSettings from "./SaleSettings";

export default {
    name: "SaleNavbarComponent",
    components: {SaleSettings, HotKeysInfo},
    data() {
        return {
            translate
        }
    },
    props: {
        SaleTab: {
            type: Array,
            default: []
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
    },
    methods: {
        ...mapActions(["signOut"]),
        goToWebSite() {
            window.open('http://hippo.uz', '_blank');
        },
        logout() {
            let vm = this;
            vm.signOut().then(() => {
                vm.$router.push("/user/login");
            });
        },
        addNewSaleTab() {
            this.SaleTab.find(tab => tab.IsActive = false)
            this.$emit('resetNewTab');
        },
        changeTab(tab) {
            this.SaleTab.forEach(item => {
                item.IsActive = false;
            });
            tab.IsActive = true;
        },
        deleteTab(indexTab, tab) {
            let vm = this;
            vm.$bvModal.msgBoxConfirm(translate.translate("frequentlyRepeated", "deystviyaOtmenitPotomNelzya"), {
                title: translate.translate("frequentlyRepeated", "XotiteUdalit"),
                okTitle: translate.translate("frequentlyRepeated", "Da"),
                cancelTitle: translate.translate("frequentlyRepeated", "Net"),
                footerClass: 'p-3',
                hideHeaderClose: false,
                centered: false
            }).then(value => {
                if (value) {
                    if (tab.Sale.SaleId) {
                        SaleService.delete(tab.Sale.SaleId);
                    }
                    vm.SaleTab.splice(indexTab, 1);
                    if (vm.SaleTab.length > 0) {
                        vm.SaleTab[0].IsActive = true;
                    } else {
                        vm.$emit('resetNewTab');
                    }
                }
            });
        },
    }
}
</script>

<style scoped>

</style>
