<template>
	<div>
		<GlobalEvents
			@keyup="keyUpHandlingInRetailSaleItemsTable"
		/>
		<b-modal id="retail-item-remove-modal" hide-header hide-footer ref="RetailItemRemoveModal">
			<h4>{{ translate.translate("frequentlyRepeated", "XotiteUdalit") }}</h4>
			<hr>
			<h6>{{ translate.translate("frequentlyRepeated", "deystviyaOtmenitPotomNelzya") }}</h6>
			<b-button-toolbar class="float-right mt-5">
				<b-button-group class="mx-1">
					<b-button @click="onCancelRemove" type="button">
						{{ translate.translate("frequentlyRepeated", "otmenit") }}
					</b-button>
				</b-button-group>
				<b-button-group class="mx-1">
					<b-button
						:disabled="isSubmittedDelete"
						type="submit"
						variant="primary"
						class="shadow-sm"
						@click="removeItem"
					>
						<b-spinner v-if="isSubmittedDelete" small></b-spinner>
						{{ translate.translate("frequentlyRepeated", "udalit") }}
					</b-button>
				</b-button-group>
			</b-button-toolbar>
		</b-modal>
		<b-table :responsive="true"
				 :items="Items"
				 hover
				 :fields="fields"
				 class="retail-items-table"
				 ref="RetailItemsTable"
				 show-empty
				 :tbody-tr-class="selectedRowClass">
			<template v-slot:cell(#)="scope">
				<small class="fw-bolder">{{ scope.index + 1 }}</small>
			</template>
			<template v-slot:cell(name)="scope">
				{{ scope.item.WarehouseItem.Item.Name | kitcut }}
			</template>
			<template v-slot:cell(price)="scope">
				<div class="d-flex align-items-center" style="width: 50px">
					{{
						scope.item.PriceCurrencyValue.Currency ? scope.item.PriceCurrencyValue.Currency.Name : ''
					}}

					<span v-if="compareSalePriceToPurchasePrice && scope.item.WarehouseItem.LastPurchasePriceCurrencyValue &&
                        scope.item.WarehouseItem.LastPurchasePriceCurrencyValue.SystemValue > scope.item.PriceCurrencyValue.SystemValue">

                        	<span :id="'tooltip-sale-item-'+scope.item.SaleItemId">
								<b-icon icon="exclamation-triangle" variant="warning"/>
							</span>
                            <b-tooltip custom-class="top-10" :target="'tooltip-sale-item-'+scope.item.SaleItemId"
									   variant="warning">
                                Sotuv narxi mahsulotni sotib olingan narxidan arzon!
                                Sotib olingan narxi: <b>{{
									scope.item.WarehouseItem.LastPurchasePriceCurrencyValue.Value | decimal
								}}</b>
                            </b-tooltip>
                    </span>

					<template v-if="offChangeSalePrice">
						{{ scope.item.PriceCurrencyValue.Value }}
					</template>
					<template v-else>
						<IMaskPriceInput v-model="scope.item.PriceCurrencyValue.Value"
										 ref="editingItemPrice"
										 @blur="editItem(scope.item)"
										 style="width: 120px;"
										 class="border-0"
										 @keyupEnter="editItem(scope.item)"
										 @focus="($event)=>{$event.target.select()}"
						/>
					</template>

				</div>
				<div class="text-danger"
					 v-if="$v.Items.$each[scope.index].PriceCurrencyValue.Value.$error && !$v.Items.$each[scope.index].PriceCurrencyValue.Value.required">
					{{ translate.translate("frequentlyRepeated", "vvediteSena") }}
				</div>
				<div class="text-danger"
					 v-if="$v.Items.$each[scope.index].PriceCurrencyValue.Value.$error && !$v.Items.$each[scope.index].PriceCurrencyValue.Value.minValue">
					Не соответствующий значения
				</div>
			</template>
			<template v-slot:cell(quantity)="scope">
				<b-button-group class="w-100" size="sm">
					<b-button
						style="max-width: 50px;"
						class="border p-0 bg-white text-dark"
						@click="decrement(scope.item)"
					>-
					</b-button>
<!--					<b-form-input
						v-model="scope.item.Quantity"
						style="max-width: 60px;"
						size="sm"
						@blur="()=>{onChangeQuantity(scope.item)}"
						@focus="($event)=>{$event.target.select()}"
						class="border border-start-0 border-end-0 rounded-0 text-center"
					/>-->

                    <IMaskPriceInput v-model="scope.item.Quantity"
                                     ref="editingItemQuantity"
                                     @blur="()=>{onChangeQuantity(scope.item)}"
                                     style="max-width: 60px;height: 30px"
                                     class="border border-start-0 border-end-0 rounded-0 text-center small"
                                     @focus="($event)=>{$event.target.select()}"
                    />

					<b-button
						style="max-width: 50px;"
						class="border p-0 bg-white text-dark"
						@click="increment(scope.item)"
					>+
					</b-button>
				</b-button-group>
			</template>
			<template v-slot:cell(total)="scope">
				<div class="d-flex align-items-center" style="width: 50px">
					{{
						scope.item.PriceCurrencyValue.Currency ? scope.item.PriceCurrencyValue.Currency.Name : ''
					}}
					<IMaskPriceInput v-model="scope.item.Total"
									 ref="editingItemPrice"
									 @blur="editItem(scope.item, 'total')"
									 style="width: 120px;"
									 class="border-0"
									 @keyupEnter="editItem(scope.item, 'total')"
									 @focus="($event)=>{$event.target.select()}"
					/>
				</div>
			</template>
			<template v-slot:cell(actions)="scope">
				<div style="width: 5px;">
					<b-icon-trash class="cursor-pointer align-middle" @click="showDeleteItemModal(scope.item)"/>
				</div>
			</template>
			<template #empty="scope">
				<div class="text-center">
					<span>Отсканируйте штрих-код продукта</span>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import math from "../../../src/plugins/math";
import IMaskPriceInput from "../imask/IMaskPriceInput";
import {decimal, minValue, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import SaleItemService from "../../services/sale/sale.item.service";
import translate from "../../translation/translate";
import GlobalEvents from 'vue-global-events'
import {mapGetters} from "vuex";

export default {
	name: "SaleBasketListComponent",
	components: {IMaskPriceInput, GlobalEvents},
	mixins: [validationMixin],
	props: {
		Sale: {
			default: {},
			type: Object
		},
		Items: {
			default: [],
			type: Array
		}
	},
	data() {
		return {
			translate,
			selectedRow: null,
			clickedNumpad: null,
			timeout: null,
			typingNumbers: '',
			isSubmittedDelete: false
		}
	},
	validations: {
		Items: {
			$each: {
				PriceCurrencyValue: {
					Value: {
						required,
						decimal,
						minValue: minValue(0),
					}
				}
			}
		}
	},
	computed: {
		...mapGetters({
			'getCheckedItemStateMoreThanZero': 'getCheckedItemStateMoreThanZero',
			"offChangeSalePrice": "offChangeSalePrice",
			compareSalePriceToPurchasePrice: "getCompareSalePriceToPurchasePrice",
		}),
		manualModalValue() {
			return this.$parent.isActiveManualAddProduct
		},
		fields() {
			return [
				'#',
				{key: "name", label: "Названия товара", class: 'width-500'},
				{key: "price", label: "Цена"},
				{key: "quantity", label: "Количество"},
				{key: "total", label: "Общая сумма"},
				{key: "actions", label: ""}
			]
		},
	},
	methods: {
		showDeleteItemModal(item) {
			this.selectedRow = item;
			this.$bvModal.show('retail-item-remove-modal');
		},
		onCancelRemove() {
			this.$bvModal.hide('retail-item-remove-modal');
			this.selectedRow = null;
		},
		selectedRowClass(item) {
			if (!item || !this.selectedRow) {
				return '';
			}
			if (item.SaleItemId === this.selectedRow.SaleItemId) {
				return 'table-secondary';
			} else {
				return '';
			}
		},
		keyUpHandlingInRetailSaleItemsTable(event) {
			if (!event)
				return;
			if (!this.manualModalValue && !this.focusInField) {
				switch (event.code) {
					case 'ArrowDown':
						this.onClickArrowDown();
						break;
					case 'ArrowUp':
						this.onClickArrowUp();
						break;
					case 'ArrowRight':
						if (this.selectedRow)
							this.increment(this.selectedRow);
						break;
					case 'ArrowLeft':
						if (this.selectedRow)
							this.decrement(this.selectedRow);
						break;
					case 'Numpad1':
					case 'Numpad2':
					case 'Numpad3':
					case 'Numpad4':
					case 'Numpad5':
					case 'Numpad6':
					case 'Numpad7':
					case 'Numpad8':
					case 'Numpad9':
					case 'NumpadDecimal':
					case 'Numpad0':
						let vm = this;
						clearTimeout(vm.timeout);
						vm.typingNumbers = vm.typingNumbers ? vm.typingNumbers + event.key : event.key;
						vm.timeout = setTimeout(function () {
							vm.clickedNumpad = vm.typingNumbers;
							vm.typingNumbers = '';
						}, 2000);
						break;
					case 'NumpadAdd':
						this.clickedNumpad = this.typingNumbers;
						if (this.selectedRow) {
							this.editItem(this.selectedRow, 'quantity');
							this.selectedRow = null;
						} else {
							this.selectedRow = null;
							this.typingNumbers = '';
						}
						break;
					case 'NumpadMultiply':
						this.clickedNumpad = this.typingNumbers;
						if (this.selectedRow) {
							this.editItem(this.selectedRow, 'price');
							this.selectedRow = null;
						} else {
							this.selectedRow = null;
							this.typingNumbers = '';
						}
						break;
					case 'NumpadDivide':
						this.clickedNumpad = this.typingNumbers;
						if (this.selectedRow) {
							this.editItem(this.selectedRow, 'quantityByPrice');
							this.selectedRow = null;
						}

						break;
					case 'Delete':
						if (this.selectedRow)
							this.$bvModal.show('retail-item-remove-modal');
						break;
					case 'Enter':
						if (this.selectedRow && this.$refs.RetailItemRemoveModal.isShow)
							this.removeItem();
						break;
					case 'Escape':
						this.selectedRow = null;
						this.typingNumbers = '';
				}
			}
		},
		onClickArrowUp() {
			if (this.Items.length === 0) return;
			if (!this.selectedRow) {
				this.selectedRow = this.Items[this.Items.length - 1];
			} else {
				let selectedRowIndex = this.Items.findIndex(item => item.SaleItemId === this.selectedRow.SaleItemId);
				if (selectedRowIndex > 0) {
					this.selectedRow = this.Items[selectedRowIndex - 1];
				} else if (selectedRowIndex === 0) {
					this.selectedRow = this.Items[this.Items.length - 1];
				}
			}
		},
		onClickArrowDown() {
			if (this.Items.length === 0)
				return;
			if (!this.selectedRow) {
				this.selectedRow = this.Items[0];
			} else {
				let selectedRowIndex = this.Items.findIndex(item => item.SaleItemId === this.selectedRow.SaleItemId);
				if (this.Items[selectedRowIndex + 1])
					this.selectedRow = this.Items[selectedRowIndex + 1];
				else
					this.selectedRow = this.Items[0];
			}
		},
		async editItem(editingItem, field) {
			let vm = this;
			if ((field === 'quantity' || field === 'quantityByPrice') && vm.getCheckedItemStateMoreThanZero && (editingItem.WarehouseItem.State < +vm.clickedNumpad)) {
				return this.$bvToast.toast(translate.translate("frequentlyRepeated", "RedaktirovatNeUdalasTovaraNet"), {
					title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
					autoHideDelay: 5000,
					toaster: 'b-toaster-bottom-left',
					variant: 'warning'
				});
			}
			if (field === 'price') {
				if (this.offChangeSalePrice) {
					return this.$bvToast.toast("Sizga narxni o'zgatrishga ruhsat o'chirilgan", {
						title: "Ruhsat o'chirilgan",
						autoHideDelay: 5000,
						toaster: 'b-toaster-bottom-left',
						variant: 'warning'
					});
				}
				editingItem.PriceCurrencyValue.Value = +vm.clickedNumpad;
			} else if (field === 'quantity') {
				editingItem.Quantity = +vm.clickedNumpad;
			} else if (field === 'quantityByPrice') {
				editingItem.Quantity = Math.round10(+vm.clickedNumpad / +editingItem.PriceCurrencyValue.Value, -4);
			} else if (field === 'total') {
				editingItem.Quantity = Math.round10(+editingItem.Total / +editingItem.PriceCurrencyValue.Value, -4);
			}
			await SaleItemService.edit(editingItem.SaleItemId, {
				sale: vm.Sale,
				saleItem: editingItem
			}).then((updatedItem) => {
				vm.$emit('reloadSale');
				vm.clickedNumpad = null;
				vm.typingNumbers = '';
			});
		},
		onChangeQuantity(editingItem) {
			if (this.getCheckedItemStateMoreThanZero && (editingItem.WarehouseItem.State < editingItem.Quantity)) {
				return this.$bvToast.toast(translate.translate("frequentlyRepeated", "RedaktirovatNeUdalasTovaraNet"), {
					title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
					autoHideDelay: 5000,
					toaster: 'b-toaster-bottom-left',
					variant: 'warning'
				});
			}
			this.editItem(editingItem)
		},
		decrement(saleItem) {
			if (saleItem.Quantity <= 1)
				return;
			saleItem.Quantity = saleItem.Quantity - 1;
			this.editItem(saleItem);
		},
		increment(saleItem) {
			if (this.getCheckedItemStateMoreThanZero && (saleItem.WarehouseItem.State < saleItem.Quantity + 1)) {
				return this.$bvToast.toast(translate.translate("frequentlyRepeated", "RedaktirovatNeUdalasTovaraNet"), {
					title: translate.translate("frequentlyRepeated", "VozniklaOshibka"),
					autoHideDelay: 5000,
					toaster: 'b-toaster-bottom-left',
					variant: 'warning'
				});
			}
			saleItem.Quantity = saleItem.Quantity + 1;
			this.editItem(saleItem);
		},
		onEditingItemPrice($event) {
			$event.target.select();
		},
		//remove item
		removeItem() {
			let vm = this;
			vm.isSubmittedDelete = true;
			SaleItemService.delete(vm.selectedRow.SaleItemId).then(() => {
				let index = vm.Items.indexOf(vm.selectedRow);
				if (index > -1) vm.Items.splice(index, 1);
				vm.$bvModal.hide('retail-item-remove-modal');
				vm.isSubmittedDelete = false;
				vm.selectedRow = null;
				vm.$emit('reloadSale');
			});
		},
		mathHelper(item1, item2) {
			return math.multiply(item1, item2)
		},
	}
}
</script>

<style>
.width-500 {
	width: 500px !important;
}

.top-10 {
	top: 10px !important;
}
</style>
