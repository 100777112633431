import BaseService from "../base/base.service";
import axios from "axios";
import {ApiHost} from "../../constants/settings";

class EPosSaleService extends BaseService {
    get modelName() {
        return 'EPosSale'
    }

    get(saleId, params) {
        return axios.get(`${ApiHost}/api/EPosSale/${saleId}`, {params: params})
    }

    edit(saleId, params) {
        return axios.put(this.getEditUrl(saleId), params);
    }
}

export default new EPosSaleService();
