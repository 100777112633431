<template>
    <v-select ref="select"
              class="w-100"
              style="min-width: 200px"
              v-model="employee"
              @search="onSearch"
              :options="employees"
              label="FullName"
              :filterable="false"
              :clearable="isAllowClear"
              :select-on-tab="true"
              @input="onInput"
              :placeholder='translation.translate("frequentlyRepeated", "ViberiteSotrudnika")'
              v-on:search:focus="onFocus"
              v-on:search:blur="() => { $emit('blur') }"
    >
        <template slot="no-options">
            {{ translation.translate("frequentlyRepeated", "SotrudnikNeNayden") }}
        </template>
    </v-select>
</template>
<script>
import {debounce} from "underscore";
import vSelect from "vue-select";
import translate from "@/translation/translate";
import EmployeeService from '../../services/employee/employee.service'

export default {
    props: ['value', 'clearable'],
    name: 'EmployeeSearchElement',
    components: {
        'v-select': vSelect,
    },
    data() {
        return {
            vSelectHeight: null,
            employees: [],
            employee: null,

            isAllowClear: false,
            translation: translate,
            specificKey: Math.random().toString(16).slice(2),
        };
    },
    methods: {
        onFocus() {
            let vm = this;
            vm.$emit('focus');
            EmployeeService.getAll({
                q: '',
            }).then((employees) => {
                vm.employees = employees;
            });
        },
        onInput(employee) {
            if (!employee)
                return this.$emit("input", null);
            if (employee.EmployeeId)
                return this.$emit("input", employee);
        },
        onSearch(search, loading) {
            this.searchDebounce(loading, search, this);
        },
        searchDebounce: debounce((loading, search, vm) => {
            if (search.length > 0) {
                loading();
                EmployeeService.getAll({
                    q: search,
                }).then((employees) => {
                    vm.employees = employees;
                    loading();
                });
            }
        }, 300),
        focus() {
            this.$refs.select.open = true;
            setTimeout(() => {
                this.$refs.select.$refs.search.focus();
            }, 50);
        },
        clear() {
            this.employee = null;
        }
    },
    created() {
        this.employee = this.value;
        this.isAllowClear = typeof (this.clearable) === "boolean" ? this.clearable : this.isAllowClear;
        setTimeout(() => {
            this.$nextTick(() => {
                this.vSelectHeight = this.$refs.select.$el.clientHeight
            })
        }, 100)
    },
    watch: {
        value(v) {
            this.employee = v;
        },
        clearable(v) {
            this.isAllowClear = v;
        }
    }
}
</script>

<style scoped>
.AddNewEmployeeIcon, .EditNewEmployeeIcon {
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-left: 0px;
}
</style>
