import axios from "axios";
import {UzPos} from '../../constants/settings';

class EPosExternalApiService {
    openZreport() {
        return axios.post(`${UzPos}`, {
            token: 'DXJFX32CN1296678504F2',
            method: 'openZreport'
        });
    }

    closeZreport() {
        return axios.post(`${UzPos}`, {
            token: 'DXJFX32CN1296678504F2',
            method: 'closeZreport'
        });
    }

    checkStatus() {
        return axios.post(`${UzPos}`, {
            token: 'DXJFX32CN1296678504F2',
            method: 'checkStatus'
        });
    }

    getStatus() {
        return axios.post(`${UzPos}`, {
            token: 'DXJFX32CN1296678504F2',
            method: 'getStatus'
        });
    }

    sendSale(data){
        return axios.post(`${UzPos}`, data);
    }
}

export default new EPosExternalApiService();
