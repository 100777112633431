import axios from "axios";
import BaseService from "../base/base.service";

class EmployeeService extends BaseService {
    get modelName() {
        return "Employee";
    }

    getAll({query}) {
        let params = {
            q: query || '',
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    create({FullName, IsDefault}) {
        return axios.post(this.getCreateUrl(), {
            FullName: FullName,
            IsDefault: IsDefault,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    edit({Id, FullName, IsDefault}) {
        return axios.put(this.getEditUrl(Id), {
            FullName: FullName,
            IsDefault: IsDefault,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default new EmployeeService()
