<template>
    <b-modal id="FavoriteProductsModal" hide-footer hide-header size="xl">
        <ItemSearchBar ref="ItemSearchBar" @selectedItem="onSelectedItemInSearchBar"/>
        <div class="text-right h2 text-muted mr-2 cursor-pointer"
             @click="$bvModal.hide('FavoriteProductsModal')"> x
        </div>
        <div class="container-fluid">
            <div class="row">
                <div v-for="(product, productIndex) in warehouseItems" :key="`product_${productIndex}`"
                     class="col-sm-12 col-lg-2 mx-2 my-3 border p-1" @click="$emit('selectedProduct', product)">
                    <div class="position-relative h6 cursor-pointer text-muted" @click="deleteFavoriteProduct(product)"
                         style="left: 100%;bottom: 18%;">x</div>
                    <div class="d-flex justify-content-between">
                        <div class="text-center cursor-pointer bg-active"
                             style="width: 100px;height: 100px">
                            {{ product.Item.Name }}
                        </div>
                        <div style="width: 100px;">
                            <ItemImageComponent
                                v-if="product.Item && product.Item.ImagePath"
                                :imageUrl="product.Item.ImagePath"
                                :imageClassName="'img-thumbnail list-thumbnail cursor-pointer'"
                            />
                            <div v-else class="ml-1 cursor-pointer">
                                <img
                                    width="200px"
                                    class="img-thumbnail list-thumbnail cursor-pointer"
                                    src="@/assets/images/image-not-found.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-2 cursor-pointer border px-4 ml-3 my-3 d-flex justify-content-center align-items-center"
                     @click="$bvModal.show('ItemSearchBarModal')">
                    <b-icon-plus class="h1 m-0"/>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import WarehouseItemService from "../../services/warehouse/warehouse.item.service.js";
import ItemSearchBar from "./ItemSearchBar";
import translate from "../../translation/translate";
import ItemImageComponent from "../image/ItemImageComponent";

export default {
    name: "FavoriteProducts",
    components: {ItemImageComponent, ItemSearchBar},
    data() {
        return {
            settings: {
                maxScrollbarLength: 60
            },
            warehouseItems: []
        }
    },
    methods: {
        deleteFavoriteProduct(warehouseItem) {
            let vm = this;
            WarehouseItemService.edit(warehouseItem.WarehouseItemId, false).then(function (response) {
                vm.loadFavoriteProducts();
            });
        },
        onSelectedItemInSearchBar(warehouseItem) {
            let vm = this;
            WarehouseItemService.edit(warehouseItem.WarehouseItemId, true).then(function (response) {
                vm.loadFavoriteProducts();
                vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                    title: translate.translate("frequentlyRepeated", "uspeshno"),
                    autoHideDelay: 5000,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'success'
                });
            });
        },
        loadFavoriteProducts() {
            let vm = this;
            WarehouseItemService.getAll({
                warehouseId: vm.$store.getters.selectedWarehouse.WarehouseId,
                type: null,
                query: '',
                desc: false,
                sort: 'name',
                skip: 0,
                limit: null,
                stateeq: null,
                statelt: null,
                stategt: null,
                statelteq: null,
                stategteq: null,
                alert: null,
                category: null,
                favourite: true
            }).then(favoriteItems => {
                vm.warehouseItems = favoriteItems;
            });
        },
    },
    mounted() {
        this.loadFavoriteProducts();
    }
}
</script>

<style scoped lang="scss">
.scroll-area {
    position: relative;
    margin: auto;
    //height: 90vh;
}
</style>
